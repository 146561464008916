exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-promotion-details-js": () => import("./../../../src/pages/promotionDetails.js" /* webpackChunkName: "component---src-pages-promotion-details-js" */),
  "component---src-pages-terminos-de-recomendacion-js": () => import("./../../../src/pages/terminos-de-recomendacion.js" /* webpackChunkName: "component---src-pages-terminos-de-recomendacion-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */)
}

